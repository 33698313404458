<template>
	<div :class="{ readonly: readonly }">
		<div class="row">
			<div v-if="!calendar.length" class="text-center mt-5 w-100 font-weight-bold">Ľutujeme, ale nie sú dostupné žiadné voľné termíny.</div>
			<div v-for="(month, i) in calendar" :key="i" class="col-lg-6">
				<div>
					<div class="font-weight-bold text-center my-4">{{ monthNames[month.month] }} {{ month.year }}</div>
					<div class="week header mb-1">
						<div v-for="day in days" :key="day" class="day spacer">{{ day }}</div>
					</div>
					<div v-for="(week, w) in month.rows" :key="w" class="week">
						<z-day v-for="(day, d) in week" :key="d" :day="day" :spacer="!day" :occupied="isOccupied(day, month.month, month.year)" :disabled="isDisabled(day, month.month, month.year)" :selected="isSelected(day, month.month, month.year)" @click.native="toggleDay(day, month.month, month.year)" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	components: {
		'z-day': () => import('@/plugins/app@components/day/z-day.vue')
	},

	props: {
		selDays: { type: Object, required: true },
		readonly: { type: Boolean, default: true },
		enabledDays: { type: Array, default: () => [] },
		occupiedDays: { type: Array, default: () => [] },
		fromDate: { required: true },
		toDate: { required: true }
	},

	data() {
		return {
			days: ['Po', 'Ut', 'St', 'Št', 'Pi', 'So', 'Ne'],
			monthNames: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December']
		}
	},

	computed: {
		calendar() {
			return this._getMonthsBetween().map(({ year, month }) => {
				return {
					year,
					month: month,
					rows: this._generateMonthRows(month, year)
				}
			})
		},

		selectableDays() {
			return this.enabledDays
				.filter((d) => {
					const [year, month, day] = d.split('-')
					return !this.isDisabled(Number(day), Number(month) - 1, Number(year))
				})
				.map((d) => new Date(d))
		}
	},

	watch: {
		selectableDays(val) {
			this.$emit(
				'available-week-days-changed',
				val
					.map((d) => d.getDay())
					.filter((d, i, self) => self.indexOf(d) == i)
					.map((d) => 'd' + d)
			)
		}
	},

	methods: {
		isOccupied(day, month, year) {
			const dateString = `${year}-${month + 1 < 10 ? '0' + (month + 1) : month + 1}-${day < 10 ? '0' + day : day}`
			return !!(this.occupiedDays.length && this.occupiedDays.includes(dateString))
		},

		isDisabled(day, month, year) {
			const dateString = `${year}-${month + 1 < 10 ? '0' + (month + 1) : month + 1}-${day < 10 ? '0' + day : day}`
			if (this.enabledDays.length && !this.enabledDays.includes(dateString)) return true

			const date = new Date(year, month, day).getTime()
			const today = new Date(new Date().setHours(0, 0, 0, 0))

			today.setDate(today.getDate() + 2)

			if (!day || date < today) return true

			return false
		},

		isSelected(day, month, year) {
			const date = `${year}-${month + 1 < 10 ? '0' + (month + 1) : month + 1}-${day < 10 ? '0' + day : day}`
			return this.selDays[date] && this.selDays[date].selected
		},

		toggleDay(day, month, year) {
			if (this.readonly || this.isDisabled(day, month, year)) return

			return this._toggleDay(day, month, year)
		},

		_toggleDay(day, month, year) {
			const key = moment(`${day}-${month + 1}-${year}`, 'DD-MM-YYYY').format('YYYY-MM-DD')
			this.$emit('day-selected', { date: key })
		},

		_getMonthsBetween() {
			// filter out days before startdate
			const selectableDays = this.selectableDays.filter((d) => new Date(d) > this.fromDate.getTime())

			const startDate = moment(new Date(Math.min.apply(null, selectableDays))).set('date', 1)
			const endDate = moment(this.toDate).set('date', 2)
			const result = []

			while (startDate.isBefore(endDate)) {
				const [year, month] = startDate.format('YYYY-MM').split('-')
				result.push({ year: Number(year), month: Number(month) - 1 })
				startDate.add(1, 'month')
			}

			return result
		},

		_getNumberOfDaysInMonth(month, year) {
			return new Date(year, month + 1, 0).getDate()
		},

		_generateMonthRows(month, year) {
			const numberOfDaysInMonth = this._getNumberOfDaysInMonth(month, year)

			const monthArray = []
			let arr = []
			for (let i = 1; i <= numberOfDaysInMonth; i++) {
				const dayDate = new Date(year, month, i)
				const dayNum = dayDate.getDay()

				if (dayNum == 1) {
					arr = [...new Array(7 - arr.length).fill(0), ...arr]
					monthArray.push(arr)
					arr = [i]
					continue
				}

				arr.push(i)
			}

			if (arr.length) {
				arr = [...arr, ...new Array(7 - arr.length).fill(0)]
				monthArray.push(arr)
			}

			return monthArray
		}
	}
}
</script>

<style lang="sass" scoped>
.week
	display: flex
	justify-content: space-around

.web
	.week.header
		border-bottom: 1px solid #E8E4DA
		border-top: 1px solid #E8E4DA
		font-weight: bold
</style>
